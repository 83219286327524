
















































































import DatePicker from '@/components/ui/DatePicker.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import { FormaDePagamento, Titulo } from '@/models'
import { BaixaDePagamentoForm } from '@/models/financeiro/BaixaDePagamento'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CreateBaixaDePagamentoUseCase } from '@/usecases'
import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator'
import moment from 'moment-timezone'
import { imprimirComprovanteDeRecebivel } from '@/shareds/venda-shareds'

@Component({
	components: {
		DatePicker,
	},
})
export default class DialogoDeEdicaoDeBaixasDePagamento extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean, default: false }) retornoCrediario!: boolean


	buscando = false
	salvando = false
	mostra = false
	menu2 = false
	time = null
	titulo: Titulo | null = null
	valorFaltante: number | null = null
	formatarMoeda = formatarMoeda
	formaDePagamento = ''

	dataPagamento: string | null = null

	createBaixaDePagamentoUseCase = new CreateBaixaDePagamentoUseCase()

	baixaDePagamento: BaixaDePagamentoForm = {
		titulo: null,
		transactionId: '',
		valorPagamento: 0,
		valorTaxa: 0,
		dataHoraPagamento: '',
		isTaxa: false,
		isIntegracao: false,
		formaDePagamento: null,
	}

	opcoesDeFormaDePagamento: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Voucher',
		'Crediário',
		'TEF Crédito',
		'TEF Débito',
		'TEF Pix',
		'Pix',
		'Outros',
	]

	mostrarDialogo(titulo: Titulo | null, valorFaltante: number) {
		this.mostra = true
		this.titulo = titulo ? titulo : null
		this.valorFaltante = valorFaltante
	}

	async salvarBaixa() {
		if (!this.baixaDePagamento) return
		if (!this.titulo) return

		if (!this.baixaDePagamento.valorPagamento || this.baixaDePagamento.valorPagamento <= 0) {
			return AlertModule.setError('Valor de pagamento é obrigatório!')
		}
		if (this.valorFaltante === null) {
			return AlertModule.setError('Valor faltante não pode ser nulo!')
		}
		
		const valorPagamento = Number(this.baixaDePagamento.valorPagamento);
		const valorTaxa = Number(this.baixaDePagamento.valorTaxa);
		const totalPagamento = Math.round((valorPagamento + valorTaxa) * 100) / 100;
		const valorFaltanteArredondado = Math.round(this.valorFaltante * 100) / 100;

		if (totalPagamento > valorFaltanteArredondado) {
			return AlertModule.setError('Valor de pagamento acrescido da taxa não pode ser maior que o valor')
		}
		if ( this.baixaDePagamento.valorTaxa > this.valorFaltante) {
			return AlertModule.setError('Valor de taxa não pode ser maior que o valor faltante!')
		}
		if (!this.formaDePagamento) {
			return AlertModule.setError('Forma de pagamento é obrigatória!')
		}

		try {
			this.salvando = true
			this.baixaDePagamento.titulo = this.titulo

			this.baixaDePagamento.formaDePagamento = this.formaDePagamento

			const comprovanteDaBaixa = await this.createBaixaDePagamentoUseCase.create(this.baixaDePagamento)
			await imprimirComprovanteDeRecebivel(comprovanteDaBaixa)
			this.$emit('atualizar', this.baixaDePagamento)
			this.limparDados()
	
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.mostra = false
		}
	}

	async limparDados() {
		this.baixaDePagamento = {
			titulo: null,
			transactionId: '',
			valorPagamento: 0,
			valorTaxa: 0,
			dataHoraPagamento: '',
			isTaxa: false,
			isIntegracao: false,
			formaDePagamento: null,
		}
		this.time = null
		this.dataPagamento = null
		this.mostra = false
	}

	cancelar() {
		this.limparDados()
	}

	gerarDataHora(data?: string | null, hora?: string | null): string {
		const dateTimeString = `${data}T${hora}:00`;
		const localDateTime = moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss');
		const utcDateTime = localDateTime.utcOffset(0, true); // Define o offset para 0 (UTC)
		const utcDateTimeString = utcDateTime.toISOString();

		return utcDateTimeString;
	}



	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return

		window.requestAnimationFrame(() => {
			this.form.resetValidation()
		})
	}
}
