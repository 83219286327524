




























import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { VendaServiceAdapter } from '@/usecases'
import { Inject } from 'inversify-props'
import DatePicker from '@/components/ui/DatePicker.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'

@Component({ 
	components: {
		DatePicker,
	},
})
export default class DialogoDeEdicaoDoPrazoDeDevolucao extends Vue {
	@Prop({ type: String }) idDaVenda?: string 
	@Prop({ type: String })	prazoDevolucao?: string

	@Inject('VendaServiceAdapter')
	private vendaService!: VendaServiceAdapter

	salvando = false
	mostra = false
	prazoDeDevolucao = ''

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	created() {
		this.prazoDeDevolucao = this.prazoDevolucao || ''
	}

	mostrar(){
		this.mostra = true
	}

	async confirmar(){
		if (!this.idDaVenda) {
			this.mostra = false
			return
		}

		try {
			this.salvando = true
			await this.vendaService.update(this.idDaVenda, {
				prazoDevolucao: moment.utc(`${this.prazoDeDevolucao} 23:59:59`).tz('UTC').toISOString(true),
				produtosVerificados: false,
			})
			this.$emit('atualizarPrazoDeDevolucao', this.prazoDeDevolucao)
			AlertModule.setSuccess('Prazo atualizado com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
		this.mostra = false
	}
}
