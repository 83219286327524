












































import { MotivoCancelamento, Page, Pageable } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindMotivosDeCancelamentoUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import {PropSync, Ref, Vue, Watch, Component } from 'vue-property-decorator'

@Component
export default class SeletorDeMotivosDeCancelamento extends Vue {
	@Ref() campo!: HTMLInputElement
	@PropSync('value') selecionado?: MotivoCancelamento | MotivoCancelamento[] | string | string[]

	busca: string | null = null
	motivos: MotivoCancelamento[] = []
	carregando = true

	pageable: Pageable = {
		page: 0,
		size: 3,
	}
	pagina: Page<MotivoCancelamento> | null = null
	cancelToken: CancelTokenSource | null = null
	findMotivosDeCancelamentoUseCase = new FindMotivosDeCancelamentoUseCase

	created() {
		this.carregarSelecao()
	}
	
	get carregouTodosOsMotivos() {
		return this.pagina?.last || false
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}

	get motivosFiltrados() {
		return this.motivos
	}

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1

		await this.find()
	}

	extrairId(objeto: MotivoCancelamento | string | null): string {
		if (objeto === null) {
			throw new Error("Motivo não encontrado");
		}
		if (typeof objeto === 'object') {
			return objeto.id as string;
		}
		return objeto.toString();
	}

	async carregarSelecao() {
		if (!this.selecionado) {
			return this.find()
		}
		const ids: string[] = Array.isArray(this.selecionado)
			? (this.selecionado as (string | MotivoCancelamento)[]).map(this.extrairId)
			: [this.extrairId(this.selecionado)]
		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findMotivosDeCancelamentoUseCase.findAll({
				ids,
			}, axiosConfig)
			this.pagina.content.forEach(loja => {
				this.motivos.push(loja)
			})
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async find() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			this.pagina = await this.findMotivosDeCancelamentoUseCase.findAll({
				page: this.pageable.page || 0,
				size: this.pageable.size,
				busca: this.busca || undefined,
			},
			{
				cancelToken: this.cancelToken.token,
			})
			this.pagina.content.forEach(motivo => {
				if (this.motivos.some(({ id }) => motivo.id === id)) return
				this.motivos.push(motivo)
			})
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	
	@Watch('busca', { deep: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.find()
	}

	focus() {
		this.campo.focus()
	}
}
