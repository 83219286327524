























import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator';
import DataTableDeCrud from '../ui/DataTableDeCrud.vue';
import DialogoDeVincularLojasComCliente from '../cliente/DialogoDeVincularLojasComCliente.vue';
import { FormLoja, Loja } from '@/models';
import { DataOptions } from 'vuetify';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import axios, { CancelTokenSource } from 'axios';
import { FindLojaUseCase, SaveClienteUseCase, SaveLojaUseCase } from '@/usecases';


@Component({
	components: {
		DataTableDeCrud,
		DialogoDeVincularLojasComCliente,
	},
})
export default class ListagemDeLojaPorCliente extends Vue {
	@Prop({ type: String }) clienteId!: string
	@Ref() dialogoDeVincularLojasComCliente!: DialogoDeVincularLojasComCliente

	carregando = true
	loading = false
	mostrar = false

	totalRegistros = -1

	headers = [
		{ text: 'Nome', value: 'nomeFantasia' },
		{ text: 'Cnpj', value: 'cnpj'},
	]

	cancelToken: CancelTokenSource | null = null
	lojas: Loja[] = []

	findLojaUseCase = new FindLojaUseCase()
	saveLojaUseCase = new SaveLojaUseCase()
	saveClienteUseCase = new SaveClienteUseCase()
	
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	abrirDialogoDeVincualarLojas() {
		this.dialogoDeVincularLojasComCliente.mostrar()
	}

	@Watch('mostrar')
	created() {
		if(!this.mostrar) return 
		this.buscar()
	}

	@Watch('paginacao')
	async buscar () {
		if(!this.clienteId) return
		try {
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				clienteId: this.clienteId || undefined,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const pagina = await this.findLojaUseCase.findLojasVinculadasComCliente(params, axiosConfig)
			this.lojas = pagina.content
			this.totalRegistros = pagina.totalElements
			this.carregando = false
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	async updateLojas(lojasUpdate: FormLoja[]) {
		try {
			await this.saveClienteUseCase.vincularLojasComCliente(this.clienteId, lojasUpdate)
		} catch(e) {
			AlertModule.setError(e)
		} finally {
			this.buscar()
		}
	}

	async deleteLoja(indice: number) {
		this.loading = true
		const lojaSelecionada = this.lojas[indice]
		try {
			await this.saveLojaUseCase.desvincularLojaECliente(lojaSelecionada.id, this.clienteId)
		} catch(e) {
			this.loading = false
			AlertModule.setError(e)
		} finally {
			this.loading = false
			this.lojas.splice(indice, 1)
			AlertModule.setSuccess("Loja desvinculada com sucesso")
		}
	}

	get lojasFormatadas() {
		return this.lojas
	}
}
