




























import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { EditarUmaVendaUseCase } from '@/usecases'
import DatePicker from '@/components/ui/DatePicker.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ 
	components: {
		DatePicker,
	},
})
export default class DialogoDeEdicaoDeVolumesDaVenda extends Vue {
	@Prop({ type: String }) idDaVenda?: string 
	@Prop({ type: [] })	volumesExistentes?: string[]

	editarUmaVendaUseCase: EditarUmaVendaUseCase = new EditarUmaVendaUseCase()

	salvando = false
	mostra = false
	volumes = ['']

	created() {
		this.volumes = this.volumesExistentes || []
	}

	mostrar(){
		this.mostra = true
	}

	async confirmar(){
		setTimeout(() => this.salvar(), 10)
	}

	async salvar(){
		if (!this.idDaVenda) {
			this.mostra = false
			return
		}
		
		try {
			this.salvando = true
			await this.editarUmaVendaUseCase.executar(this.idDaVenda, {
				volumes: this.volumes,
				produtosVerificados: false,
			})

			this.$emit('atualizarVolumes', this.volumes)
			AlertModule.setSuccess('Volumes atualizados com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
		this.mostra = false
	}
}
