import { FluxoDoTitulo, FormDeTitulo } from '@/models'

export function criarTitulo(fluxo: FluxoDoTitulo): FormDeTitulo {
	return {
		dataDeVencimento: null,
		dataDeCompetencia: null,
		valor: null,
		pessoa: null ,
		fluxo: fluxo,
		situacao: 'Aberto',
		descricao: '',
		loja: null,
		formaDePagamento: null,
		valorAtualizado: null,
		juros: null,
		multa: null,
		temParcelamento: false,
		tipoDePagamento: null,
		baixaPagamento: null,
	}
}