





























































































import { ResumoDaVenda, Venda } from '@/models';
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils';
import { obterDisplayClienteDaVenda } from '@/shareds/venda-shareds';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { VendaModule } from '@/store/vuex/venda/VendaStore';
import { EdicaoDeUmaVenda, EditarUmaVendaUseCase, FindVendaUseCase } from '@/usecases';
import { Vue, Component, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';
import DialogoDeDetalhesDaVenda from './DialogoDeDetalhesDaVenda.vue';
import Confirmacao from '../ui/Confirmacao.vue';
import RodapePersonalizado from '../ui/data-tables/RodapePersonalizado.vue';

@Component({
	components: {
		DialogoDeDetalhesDaVenda,
		Confirmacao,
		RodapePersonalizado,
	},
})

export default class DialogoDeEdicaoDeVendaOrigem extends Vue {
	@PropSync('value') vendaDeReferencia!: Venda
	@Prop({ type: String }) cpfDoCliente?: string | null
	@Prop({ type: String }) identificadorInicial!: string | null
	@Ref() form!: HTMLFormElement
	
	salvando = false
	buscandoVendas = false
	filtroConsignado = false
	filtroDemonstracao = false


	mostra: boolean | null = null
	vendasResumidas: ResumoDaVenda[] = []
	cpfOuCnpjDoCliente: string | null = null
	vendaParaEditar: Venda | null = null
	vendaEditada: EdicaoDeUmaVenda | null = null

	findVendaUseCase = new FindVendaUseCase()
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 0,
	}

	headersVendas = [
		{ text: 'Identificador', value: 'identificador', sortable: false },
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'Cliente', value: 'displayCliente', sortable: false },
		{ text: 'Data', value: 'diplayDataHora', sortable: false },
		{ text: 'Total', value: 'total', sortable: false },
		{ text: 'Ações', value: 'actions', align: 'center' },
	]

	get vendasFormatadas() {
		return this.vendasResumidas.map(venda => ({
			...venda,
			displayCliente: obterDisplayClienteDaVenda(venda),
			diplayDataHora: this.diplayDataHora(venda),
		}))
	}

	diplayDataHora(venda: ResumoDaVenda) {
		if (!venda.dataHora) return ''
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	async buscar() {
		if (!VendaModule.lojaDaVenda) return
		if (!this.cpfOuCnpjDoCliente) return
		let vendasPaginadas
	
		try {
			this.buscandoVendas = true
			vendasPaginadas = await this.findVendaUseCase.list({
				lojaId: null,
				cliente: this.cpfOuCnpjDoCliente ? this.cpfOuCnpjDoCliente : undefined,
				identificadorProduto: undefined,
				isConsignado: this.filtroConsignado,
				isDemonstracao: this.filtroDemonstracao,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				sort: 'dataHora,desc',
			})
		} catch(error) {
			AlertModule.setError(error)
			return
		} finally {
			this.buscandoVendas = false
		}

		this.vendasResumidas = vendasPaginadas.content
		this.paginacao.itemsLength = vendasPaginadas.totalElements
	}

	async atualizarVenda(vendaNova: Venda) {
		if (!this.vendaDeReferencia) return

		if (!this.form.validate()) return
		
		try {
			this.salvando = true
			const vendaAtualizada = await this.editarUmaVendaUseCase.patchVendaOrigem(
				this.vendaDeReferencia.id,
				vendaNova.id,
			)
			this.$emit('confirmar', vendaAtualizada)
			this.mostra = false
			AlertModule.setSuccess('Venda salva com sucesso')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	atualizarPagina(page) {
		this.paginacao.page += page
		this.buscar()
	}

	mostrar() {
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}

	@Watch('filtroConsignado')
	onChangefiltroConsignado() {
		if(this.filtroConsignado) {
			this.filtroDemonstracao = false
		}
		this.buscar()
	}

	
	@Watch('filtroDemonstracao')
	onChangefiltroDemonstracao() {
		if(this.filtroDemonstracao) {
			this.filtroConsignado = false
		}
		this.buscar()
	}
	

	@Watch('mostra')
	onChangeMostra() {
		if(!this.mostrar) return 
		if (this.cpfDoCliente !== undefined) {
			this.cpfOuCnpjDoCliente = this.cpfDoCliente
		}
		if (this.vendaDeReferencia !== undefined) {
			this.vendaParaEditar = this.vendaDeReferencia
		}
		this.buscar()
	}

}
