



































































































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import { Inject } from 'inversify-props'
import { ClienteServiceAdapter, VoucherServiceAdapter } from '@/usecases'
import axios, {CancelTokenSource } from 'axios'
import { FormVoucher, Voucher } from '@/models'
import { ObserveVisibility } from 'vue-observe-visibility'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { formatarMoeda } from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { voucherEstaExpirado } from '@/shareds/cliente-shareds'
import DialogoVoucherAvulso from '@/components/voucher/DialogoVoucherAvulso.vue'
import { DataOptions } from 'vuetify'
import DataTableDeCrudPaginado from '../ui/DataTableDeCrudPaginado.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeDetalhesDoCashback from './DialogoDeDetalhesDoCashback.vue'

@Component({
	components: {
		DataTableDeCrudPaginado,
		Confirmacao,
		DialogoVoucherAvulso,
		DialogoDeDetalhesDoCashback,
	},
	directives: {
		ObserveVisibility,
	},
})
export default class ListagemDeVouchers extends Vue {
	@Ref() confirmacaoDeInativarVoucher!: Confirmacao
	@Ref() dialogoDeVoucher!: DialogoVoucherAvulso
	@Prop({ type: String }) clienteId!: string

	@Inject('VoucherServiceAdapter')
	private voucherService!: VoucherServiceAdapter

	@Inject('ClienteServiceAdapter')
	private clienteService!: ClienteServiceAdapter

	formatarMoeda = formatarMoeda
	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	cancelToken: CancelTokenSource | null = null
	carregando = false
	pagina: Voucher[] = []
	paginaAtual = 1
	selecionado: Voucher | null = null
	expirado = false
	tipos: string[] = []
	totalVoucher = 0
	totalRegistros = -1

	voucherEstaExpirado = voucherEstaExpirado

	headers = [
		{ text: 'Gerado em', value: 'dataHoraGeracao', width: 'auto' },
		{ text: 'Valor', value: 'valor', width: 'auto' },
		{ text: 'Situação', value: 'situacao', width: 'auto' },
		{ text: 'Grupo Econômico', value: 'grupoEconomico', width: 'auto'},
		{ text: 'Tipo', value: 'tipo', width: 'auto' },
		{
			text: 'Ações',
			value: 'acoes',
			width: 'auto',
			sorteable: false,
			align: 'center',
		},
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	created() { 
		this.buscar()
	}

	get podeCriarVoucher() {
		return UserLoginStore.permiteRegraDeNegocio('pode_criar_voucher')
	}

	@Watch('paginacao')
	async buscar() {
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				sort: 'dataHoraGeracao,desc',
				expirado: this.expirado,
				tipos: this.tipos,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const pagina = await this.clienteService.getVouchers(this.clienteId, params, axiosConfig)
			const totalDeVoucher = await this.clienteService.findTotalizadorVouchersByClienteId(this.clienteId, params, axiosConfig)
			this.totalVoucher = totalDeVoucher
			this.pagina = pagina.content
			this.totalRegistros = pagina.totalElements
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	novoVoucher() {
		this.dialogoDeVoucher.mostrar()
	}

	async salvarVoucher(voucher: FormVoucher) {
		if (voucher.grupoEconomico.id === '') {
			AlertModule.setError('Informe o grupo econômico')
			return
		}

		try {
			this.dialogoDeVoucher.saving()
			await this.clienteService.addVoucher(voucher)
			this.dialogoDeVoucher.saving(false)
			this.dialogoDeVoucher.esconder()
		} catch (e) {
			AlertModule.setError(e)
		}finally {
			this.buscar()
		}
	}

	exibeConfirmacao(item: Voucher) {
		if (item.situacao == 'Inutilizado') {
			AlertModule.setError(
				'Não é possível inutilizar um voucher já inutilizado!',
			)
		} else {
			this.selecionado = JSON.parse(JSON.stringify(item))
			this.confirmacaoDeInativarVoucher.mostrar()
		}
	}

	async inutilizarVoucher(item: any) {
		if (!item) return
		try {
			item.situacao = 'Inutilizado'
			await this.voucherService.update(item)
			const indice = this.pagina?.findIndex(voucher => voucher.id === item.id)
			if (indice !== undefined && indice !== -1) {
				this.pagina?.splice(indice, 1, item)
				AlertModule.setSuccess('Voucher inativado com sucesso')
			}
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	obterSubtituloDeInativacao() {
		if (!this.selecionado) return ''
		return `Feito em ${dateTimeToPtBrFormat(this.selecionado.dataHoraGeracao)}`
	}
}
