



























import { Loja, Page, Pageable, Usuario } from '@/models'
import { FindUsuarioUseCase } from '@/usecases'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class SeletorDeUsuario extends Vue {
	@Prop({ type: String }) idUsuarioSalvo!: string | null
	@Prop({ type: Object }) loja!: Loja
	@Prop({type: Array, default: () => []}) excludes!: string[]
	@Prop({ type: Boolean, default: false }) somenteVendedor?: boolean
	@Prop({ type: Boolean, default: false }) somenteUsuariosAtivos?: boolean
	@Prop({ type: Boolean, default: false }) rotaSomenteVendedor?: boolean
	@Prop({ type: Array }) lojas?: string[]
	@Prop({ type: Array }) pdvs?: string[]

	usuarios: Usuario[] = []	
	busca = ''
	pagina: Page<Usuario> | null = null
	cancelToken: CancelTokenSource | null = null
	findUsuarioUseCase = new FindUsuarioUseCase()
	carregando = true
	usuariosCarregados = 0
	carregouTodosOsUsuarios = false

	pageable: Pageable = {
		page: 0,
		size: 5,
		sort: [],
	}

	async created() {
		this.carregando = true
		if (this.idUsuarioSalvo) {
			this.usuarios.push(await this.findUsuarioUseCase.findById(this.idUsuarioSalvo))
			return 
		}
		await this.buscar()
		this.carregando = false
	}

	async buscar() {
		this.carregando = true
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			if (this.idUsuarioSalvo) {
				this.usuarios.push(await this.findUsuarioUseCase.findById(this.idUsuarioSalvo))
			}
			this.cancelToken = axios.CancelToken.source()
			this.pagina = this.rotaSomenteVendedor 
				? await this.findUsuarioUseCase.findAllVendedores({
					page: this.busca ? this.pageable.page || -1 : this.pageable.page || 0,
					size: this.pageable.size,
					nome: this.busca || undefined,
					somenteVendedor: this.somenteVendedor,
					somenteUsuariosAtivos: this.somenteUsuariosAtivos,
					lojas: this.lojas,
					pdvs: this.pdvs,
					axiosConfig: {
						cancelToken: this.cancelToken.token,
					},
				}) 
				: await this.findUsuarioUseCase.findAll({
					page: this.busca ? this.pageable.page || -1 : this.pageable.page || 0,
					size: this.pageable.size,
					nome: this.busca || undefined,
					somenteVendedor: this.somenteVendedor,
					somenteUsuariosAtivos: this.somenteUsuariosAtivos,
					lojas: this.lojas,
					pdvs: this.pdvs,
					axiosConfig: {
						cancelToken: this.cancelToken.token,
					},
				})
			this.pagina.content.forEach(usuario => {
				this.usuarios.push(usuario)
			})
		} catch (error) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}

		return this.usuarios
	}

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1
		await this.buscar()
	}

	@Watch('busca', { deep: true, immediate: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (!buscaAnterior || busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.usuarios = []
		this.usuariosCarregados = 0
		this.buscar()
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.usuarios.length) {
			return 'Nenhum resultado encontrado...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}

	@Watch('lojas')
	onchangeLojaId() {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		this.usuarios = []
		this.usuariosCarregados = 0
		this.buscar()
	}
}

