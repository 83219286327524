








import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { Vue, Component } from 'vue-property-decorator'
import SeletorDeLojas from './SeletorDeLojas.vue'

@Component({
	components: {
		SeletorDeLojas,
	},
})
export default class SeletorDeLojasDoUsuario extends Vue {

	get possuiAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}

	get idUsuarioLogado(): string | undefined {
		return UserLoginStore.usuario?.id || undefined
	}
}
